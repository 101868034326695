<template>
  <div
    class="modal fade"
    tabindex="-10"
    id="kt_modal_add_group"
    data-bs-backdrop="static"
  >
    <div class="modal-dialog">
      <!-- <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading> -->

      <Form
        v-if="(id_group && loaded_group) || !id_group"
        class="form"
        @invalid-submit="onInvalidSubmit"
        @submit="onSubmit"
        :initial-values="formValues"
        :validation-schema="schema"
        v-slot="{ values }"
        autocomplete="off"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ id_group ? "Modifica gruppo" : "Creazione gruppo" }}
            </h5>
            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="kt_modal_add_group_closex"
              @click="closeModal"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>
          <div class="modal-body">
            <div class="card-body p-6">
              <div class="ps-5 pe-5 repeater-form">
                <div class="row pb-4">
                  <div class="col-md-7 row" style="max-height: 50rem">
                    <div class="col-12">
                      <label class="fw-bold text-gray-700 pe-3"
                        >Nome del gruppo</label
                      >
                      <Field name="name" class="form-control" />
                      <ErrorMessage v-show="view_errors" name="name" />
                    </div>
                    <div class="col-6">
                      <label class="fw-bold text-gray-700 pe-3"
                        >Num. max di sessioni</label
                      >
                      <Field name="max_sessions" class="form-control" />
                      <ErrorMessage v-show="view_errors" name="max_sessions" />
                    </div>
                    <div class="col-6">
                      <label
                        class="fw-bold text-gray-700 pe-3"
                        style="width: 15rem"
                        >Num. max di acc. per invito (inviti inviati)</label
                      >
                      <Field
                        name="max_guest_sessions"
                        class="form-control"
                        :disabled="!values.can_invitation_send"
                      />
                      <ErrorMessage
                        v-show="view_errors"
                        name="max_guest_sessions"
                      />
                    </div>
                    <div class="col-6">
                      <label class="fw-bold text-gray-700 pe-3"
                        >Sessioni Max per invito (inviti inviati)</label
                      >
                      <Field
                        name="max_session_invitation_send"
                        class="form-control"
                        :disabled="!values.can_invitation_send"
                      />
                      <ErrorMessage
                        v-show="view_errors"
                        name="max_session_invitation_send"
                      />
                    </div>
                    <div class="col-6">
                      <label class="fw-bold text-gray-700 pe-3"
                        >Num. max inviti inviabili (inviti inviati)</label
                      >
                      <Field
                        name="max_invitation_send"
                        class="form-control"
                        :disabled="!values.can_invitation_send"
                      />
                      <ErrorMessage
                        v-show="view_errors"
                        name="max_invitation_send"
                      />
                    </div>
                    <div class="col-12">
                      <div class="row h-100 mb-5" style="margin-bottom: 18rem">
                        <EtichettaGruppo
                          class="my-auto"
                          :label="
                            values && values.name
                              ? values.name
                              : 'Inserire un nome'
                          "
                          format="hex"
                          :bg_color="
                            values && values.color ? values.color : '#000000'
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5 ms-5">
                    <div class="col-12 d-flex mb-3">
                      <label class="fw-bold text-gray-700 pe-3"
                        >Può inviare inviti?</label
                      >
                      <Field
                        name="can_invitation_send"
                        class="align-self-start mt-1"
                        type="checkbox"
                        :value="true"
                        @change="
                          $event.target.checked
                            ? (values.requires_authorization = true)
                            : (values.requires_authorization = false)
                        "
                      />
                      <ErrorMessage
                        v-show="view_errors"
                        name="can_invitation_send"
                      />
                    </div>
                    <div class="col-12 d-flex" style="margin-bottom: 1.2rem">
                      <label class="fw-bold text-gray-700 pe-3"
                        >Gli inviti inviati necessitano di conferma</label
                      >
                      <Field
                        name="requires_authorization"
                        class="align-self-start mt-1"
                        type="checkbox"
                        :value="true"
                        :disabled="!values.can_invitation_send"
                      />
                      <ErrorMessage
                        v-show="view_errors"
                        name="requires_authorization"
                      />
                    </div>
                    <div class="col-12">
                      <label class="fw-bold text-gray-700 pe-3 mb-2"
                        >Colore del gruppo</label
                      >
                      <ColorPicker
                        is-widget
                        format="hex"
                        shape="square"
                        @update:pureColor="values.color = $event"
                      />
                      <Field name="color" type="hidden" class="form-control" />
                      <ErrorMessage v-show="view_errors" name="color" />
                    </div>
                  </div>
                </div>
                <div class="row pb-4">
                  <!-- se puo invitare -->
                </div>
                <div class="row pb-4"></div>
                <div class="row pb-4"></div>
                <div class="row pb-4">
                  <!-- palette -->
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="kt_modal_add_group_close_button"
              @click="closeModal"
            >
              Chiudi
            </button>
            <button
              type="submit"
              class="badge bg-light-success text-success rounded blueFit fs-6"
            >
              Salva
            </button>
          </div>
          <!-- <Loader v-else /> -->
        </div>
      </Form>
      <Loader v-else />
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

import { callFunctionAfterAlert, alertFailed } from "@/functions/swAlert";
import { API } from "@/enums/costanti";
import { useApiCall } from "@/composables/apiCall";
import EtichettaGruppo from "./EtichettaGruppo.vue";
export default {
  name: "add-persona-modal",
  components: {
    Field,
    ErrorMessage,
    Form,
    EtichettaGruppo,
  },
  props: {
    id_group: {
      type: Number,
    },
    selected_group: {
      type: Object,
    },
  },
  emits: ["getList", "closeModal"],
  setup(props, { emit }) {
    const view_errors = ref(false);
    const disableButton = ref(false);
    const onInvalidSubmit = (v) => {
      view_errors.value = true;
      alertFailed("Inserire i campi obbligatori");
    };
    const onSubmit = async (values, { resetForm }) => {
      await callFunctionAfterAlert(
        props.id_group ? API.GROUPS_EDIT : API.GROUPS_ADD,
        { ...values, id_group: props.id_group },
        `Sei sicuro di voler ${
          props.id_group ? "modificare" : "creare"
        } il gruppo <br> <b>${values.name}</b>?`,
        `${
          props.id_group
            ? "Gruppo modificato con successo"
            : "Gruppo creato con successo"
        }`,
        "Attenzione! Si è verificato un errore. Riprovare più tardi"
      ).then((isAddedorModifiedGroup) => {
        if (!props.id_group) {
          resetForm();
        }

        if (isAddedorModifiedGroup) {
          document.getElementById("kt_modal_add_group_close_button").click();
          emit("getList");
          setTimeout(() => {
            resetForm();
          }, 500);
        }
      });
    };
    const schema = yup.object().shape({
      name: yup.string().required("Obbligatorio"),
      color: yup.string().required("Obbligatorio"),
      max_sessions: yup
        .number()
        .typeError("Inserire un numero")
        .nullable()
        .required("Obbligatorio"),
      can_invitation_send: yup.boolean(),
      max_session_invitation_send: yup
        .number()
        .nullable()
        .when(["can_invitation_send"], {
          is: (can_invitation_send) => {
            return can_invitation_send;
          },
          then: (schema) =>
            schema.required("Obbligatorio").typeError("Inserire un numero"),
        }),
      max_guest_sessions: yup
        .number()
        .nullable()
        .when(["can_invitation_send"], {
          is: (can_invitation_send) => {
            return can_invitation_send;
          },
          then: (schema) =>
            schema.required("Obbligatorio").typeError("Inserire un numero"),
        }),
      max_invitation_send: yup
        .number()
        .nullable()
        .when(["can_invitation_send"], {
          is: (can_invitation_send) => {
            return can_invitation_send;
          },
          then: (schema) =>
            schema.required("Obbligatorio").typeError("Inserire un numero"),
        }),
    });

    const { element: lookups, getElement: get_lookups } = useApiCall(
      API.LOOKUP_GET,
      { keys: "ll|gg" }
    );

    get_lookups();

    const closeModal = () => {
      // setTimeout(() => {
      emit("closeModal");
      // }, 1000);
    };

    const payload = ref({
      id_group: props.id_group,
    });

    const {
      element: group,
      loaded: loaded_group,
      getElement: get_group,
      status: status_group,
    } = useApiCall(API.GROUPS_VIEW, payload);

    watch(
      () => props.id_group,
      async (val) => {
        if (val) {
          payload.value = {
            id_group: val,
          };
          await get_group();
          formValues.value = { ...group.value.results[0] };
        } else {
          formValues.value = {};
        }
      }
    );

    const formValues = ref({});
    return {
      group,
      loaded_group,
      formValues,
      lookups,
      schema,
      onSubmit,
      onInvalidSubmit,
      disableButton,
      view_errors,
      closeModal,
    };
  },
  computed: {
    group_results() {
      return this.group ? this.group.results[0] : null;
    },
    lookupLanguage() {
      return this.lookups &&
        this.lookups.result[0] &&
        this.lookups.result[0].languages
        ? this.lookups.result[0].languages
        : [];
    },
    lookupGroup() {
      return this.lookups &&
        this.lookups.result[1] &&
        this.lookups.result[1].groups
        ? this.lookups.result[1].groups
        : [];
    },
  },
};
</script>

<style scoped>
.modal-content {
  width: 150%;
}

.modal-body {
  padding: 0px;
}

.modal-dialog {
  margin-top: 3rem;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
  }
}

@media (max-width: 767.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(
    .border-active
  ):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}
ErrorMessage {
  color: red;
}
</style>
