<template>
  <div>
    <TableGroups
      @getList="get_people"
      @resetFilters="resetFilters"
      :objectList="people"
      :loaded="loaded_groups"
      :status="status_people"
      @setPayload="setPayload"
      :payload="payload"
    />
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { trimInput } from "@/functions/trimInput";
import TableGroups from "../components/groups/TableGroups.vue";
import { useApiCall } from "@/composables/apiCall";
import { API } from "@/enums/costanti";
export default defineComponent({
  name: "sanzioni",
  components: {
    TableGroups,
  },
  setup() {
    const payload = ref({
      // name: "string",
      // surname: "string",
      // date_of_birth: "",
      // tax_code: "",
      // phone_number: "string",
      // email: "string",
      // photo: "string",
      // id_language: 0,
      rowstoskip: 0,
      fetchrows: 100,
      sortcolumn: "",
      sortorder: "",
    });
    const setPayload = ({ name, value }) => {
      payload.value[name] = value;
    };
    const resetFilters = () => {
      payload.value = { rowstoskip: 0, fetchrows: 100 };
      get_people();
    };

    const {
      element: people,
      loaded: loaded_groups,
      getElement: get_people,
      status: status_people,
    } = useApiCall(API.GROUPS_LIST, payload);
    onMounted(async () => {
      setCurrentPageBreadcrumbs("Persone", payload);
      await get_people();
    });

    const get_groups_filters = () => {
      payload.value.rowstoskip = 0;
      get_people();
    };
    return {
      get_groups_filters,
      resetFilters,
      setPayload,
      get_people,
      people,
      loaded_groups,
      status_people,
      trimInput,
      payload,
    };
  },
});
</script>
