<template>
  <div>
    <div class="row mt-5">
      <div class="col-6">
        <div>
          <button
            type="button"
            class="btn btn-light text-gray-700 fs-6 text-start"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_add_group"
            @click="selected_id_group = null"
          >
            <i class="bi bi-plus fs-6 text-gray-700"></i>aggiungi gruppo
          </button>
          <ModalAddGroup
            :id_group="selected_id_group"
            :selected_group="selected_group"
            @getList="$emit('getList')"
            @closeModal="
              selected_id_group = null;
              selected_group = null;
            "
          />
        </div>
      </div>
      <!-- <div class="col-4 text-center">
        <div>
          <button
            type="button"
            class="btn btn-light text-gray-700 fs-6 text-start"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_add_group_csv"
            @click="selected_id_group = null"
          >
            <i class="bi bi-upload fs-6 text-gray-700"></i>importa gruppo da
            .CSV
          </button>

        </div>
      </div> -->

      <div class="col-6">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="objectList && objectList.record > 0">
            {{
              payload.rowstoskip + payload.fetchrows - (payload.fetchrows - 1)
            }}
            -
            {{
              payload.rowstoskip + payload.fetchrows > objectList.record
                ? objectList.record
                : payload.rowstoskip + payload.fetchrows
            }}
          </b>
          <b v-else> 0 </b>
          gruppi di
          <b>{{ objectList && objectList.record ? objectList.record : 0 }}</b>
        </div>
      </div>
    </div>
    <br />

    <Datatable
      :key="loaded"
      :table-header="tableHeader"
      :table-data="objectList ? objectList.results : []"
      :status="status"
      :loading="!loaded"
      :enableItemsPerPageDropdown="
        objectList && objectList.record > 25 ? true : false
      "
      :total="objectList ? objectList.record : 0"
      :rowsPerPage="100"
      :currentPage="payload.rowstoskip ? currentPage : 1"
      :order="payload.sortorder"
      :sortLabel="payload.sortcolumn"
      @sort="
        $emit('setPayload', {
          name: 'sortcolumn',
          value: $event.columnName,
        });
        $emit('setPayload', {
          name: 'sortorder',
          value: $event.order,
        });
        $emit('getList');
      "
      @items-per-page-change="
        $emit('setPayload', {
          name: 'fetchrows',
          value: $event,
        });
        $emit('getList');
      "
      @current-change="
        currentPage = $event;
        $emit('setPayload', {
          name: 'rowstoskip',
          value: payload.fetchrows * ($event - 1),
        });
        $emit('getList');
      "
      @resetFilters="$emit('resetFilters')"
    >
      <template v-slot:cell-nome="{ row: data }"
        ><div class="">
          {{ data.name }}
        </div>
      </template>
      <template v-slot:cell-colore="{ row: data }"
        ><div class="">
          <EtichettaGruppo :label="data.name" :bg_color="data.color" />
        </div>
      </template>

      <template v-slot:cell-options="{ row: data }">
        <div class="btn-group">
          <button
            class="btn btn-sm dropdown p-1"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_add_group"
            type="button"
            @click="
              selected_id_group = data.id;
              selected_group = data;
            "
          >
            <i class="bi bi-pen text-dark fs-4"></i>
          </button>
          <button
            class="btn btn-sm dropdown p-1"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            @click="deleteElement(data.id, `${data.name}`)"
          >
            <i class="bi bi-trash text-dark fs-4"></i>
          </button></div
      ></template>
    </Datatable>
  </div>
</template>

<script>
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import ModalAddGroup from "./ModalAddGroup.vue";
import EtichettaGruppo from "./EtichettaGruppo.vue";

import { ref } from "vue";
import { callFunctionAfterAlert } from "@/functions/swAlert";
import { API } from "@/enums/costanti";
import { formatDateITANoTime, isMaggiorenne } from "@/functions/formatDate.js";
import groups from "@/JSON/groups.json";

export default {
  name: "TableTornei",
  components: {
    Datatable,
    ModalAddGroup,
    EtichettaGruppo,
  },
  emits: ["getList", "resetFilters", "setPayload"],
  props: {
    objectList: {
      type: Object,
    },
    loaded: {
      type: Boolean,
    },
    status: {
      type: Number,
    },
    payload: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const tableHeader = ref([
      {
        name: "Nome",
        key: "nome",
      },
      {
        name: "Colore",
        key: "colore",
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const deleteElement = async (id_group, group_name) => {
      await callFunctionAfterAlert(
        API.GROUPS_DEL,
        { id_group },
        `Sei sicuro di voler cancellare il gruppo <b>${group_name}</b>?`,
        "Gruppo cancellato con successo",
        "Attenzione! Si è verificato un errore. Riprovare più tardi"
      ).then((deletedElement) => {
        if (deletedElement) {
          emit("getList");
        }
      });
    };

    const selected_id_group = ref(0);
    const selected_group = ref(null);

    const currentPage = ref(1);

    return {
      selected_group,
      currentPage,
      tableHeader,
      deleteElement,
      selected_id_group,
      formatDateITANoTime,
      isMaggiorenne,
      groups,
    };
  },
};
</script>

<style scoped>
.cerchio {
  position: relative;
  width: 15px; /* Dimensione del cerchio */
  height: 15px; /* Dimensione del cerchio */
  border-radius: 50%; /* Rende il cerchio */
  background-color: #3498db; /* Colore di riempimento */
  color: #fff; /* Colore del testo */
  font-size: 11px; /* Dimensione del testo */
  display: flex; /* Per allineare il testo verticalmente e orizzontalmente */
  justify-content: center;
  align-items: center;
}
</style>
